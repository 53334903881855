import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import { MainContentColumnWrapper } from '../components/layout/Layout';
import {
  Upheader,
  GradientTitle,
  BaseHeader3Styles,
  BaseHeader6Styles,
  BaseTextMediumStyles,
} from '../components/atoms/Typography/Typography';
import listElementAccent from '../assets/images/buttonAccent.svg';
import tocElementAccent from '../assets/images/accentArrow.svg';
import circlesDecorator from '../assets/images/circlesDecorator.svg';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost1024pxWide,
  forScreenAtMost1200pxWide,
} from '../styles/mediaQueries';

interface ContentPageTemplateProps {
  data: {
    markdownRemark: {
      frontmatter: {
        slug: string;
        title: string;
        description?: string;
      };
      html: string;
      tableOfContents: string;
    };
  };
}

const ContentPageTemplate = ({
  data,
}: ContentPageTemplateProps): JSX.Element => {
  const { markdownRemark } = data;
  const { frontmatter, html, tableOfContents } = markdownRemark;

  return (
    <Layout
      description={frontmatter.description}
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title={frontmatter.title}
    >
      <Wrapper>
        <MainContentColumnWrapper>
          <SectionTitle>{frontmatter.title}</SectionTitle>
          <TocWrapper>
            <TocTitle>Content</TocTitle>
            <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
          </TocWrapper>
          <Content dangerouslySetInnerHTML={{ __html: html }} />
        </MainContentColumnWrapper>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  padding: 208px 0 297px;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  position: relative;

  :before {
    position: absolute;
    content: '';
    background: url(${circlesDecorator});
    width: 100%;
    height: 916px;
    background-size: 916px;
    background-position: 50vw -100px;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    z-index: ${({ theme }) => theme.layers.base};
  }

  ${MainContentColumnWrapper} {
    position: relative;
    z-index: ${({ theme }) => theme.layers.base + 1};
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  ${forScreenAtMost1024pxWide(css`
    :before {
      background-position: 20vw -100px;
    }
  `)}

  ${forScreenAtMost680pxWide(css`
    padding: 155px 0 467px;
  `)}
`;

const SectionTitle = styled(GradientTitle).attrs({ $gradient: 'secondary' })`
  margin: 0 0 42px;
  width: 100%;
`;

const TocWrapper = styled.div`
  max-width: 250px;
  position: sticky;
  top: 100px;
  height: max-content;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      ${BaseHeader6Styles}
      position: relative;
      margin: 0 0 24px;
      transition: all 0.3s;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.texts.delicateAccented};
        transition: all 0.3s;
      }

      :before {
        content: '';
        position: absolute;
        left: -28px;
        top: 4px;
        width: 28px;
        height: 8px;
        display: inline-block;
        opacity: 0;
        transition: all 0.3s;
        mask-image: url('${tocElementAccent}');
        background-color: ${({ theme }) => theme.colors.accents.secondary};
      }
      :hover {
        padding-left: 20px;

        :before {
          opacity: 1;
        }

        a {
          color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
        }
      }
    }
  }

  ${forScreenAtMost1200pxWide(css`
    ul {
      li {
        :before {
          left: -15px;
        }
      }
    }
  `)}

  ${forScreenAtMost1024pxWide(css`
    max-width: 200px;
  `)}

  ${forScreenAtMost680pxWide(css`
    display: none;
  `)}
`;

const TocTitle = styled(Upheader).attrs({ $color: 'delicateAccented' })`
  margin: 0 0 30px;
  ${forScreenAtMost680pxWide(css`
    display: none;
  `)}
`;

const Content = styled.div`
  width: calc(100% - 280px);

  * {
    color: ${({ theme }) => theme.colors.texts.secondary};
  }

  h3 {
    ${BaseHeader3Styles}
    margin: 0 0 22px;
  }

  p {
    ${BaseTextMediumStyles}
    margin: 0 0 22px;
  }

  ul {
    margin: 0 0 26px;
    padding: 0;
    list-style: none;

    li {
      ${BaseTextMediumStyles}
      padding-left: 58px;
      position: relative;

      :before {
        content: '';
        position: absolute;
        left: 15px;
        top: 8px;
        width: 22px;
        height: 7px;
        display: inline-block;
        mask-image: url('${listElementAccent}');
        background-color: ${({ theme }) =>
          theme.colors.accents.secondaryStronger};
      }
    }
  }

  ${forScreenAtMost1024pxWide(css`
    width: calc(100% - 230px);
  `)}

  ${forScreenAtMost680pxWide(css`
    width: 100%;
  `)}
`;

// https://www.gatsbyjs.com/docs/how-to/routing/adding-markdown-pages/#create-a-collection-route-for-the-markdown-files
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      tableOfContents
      frontmatter {
        slug
        title
        description
      }
    }
  }
`;

export default ContentPageTemplate;
